const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')
const randomBoxShadow = require('./box-shadow')

const textAlign = sample(['center', 'right', 'justify', 'left'])

const textDecorationStyle = sample([
  'solid',
  'double',
  'dotted',
  'dashed',
  'wavy'
])

const textDecorationLine = sample([
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'underline',
  'overline',
  'line-through',
  'blink'
])

const backgroundImageRepeat = sample([
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'repeat',
  'repeat-x',
  'repeat-y',
  'space'
])

const backgroundSize = sample(['cover', 'contain'])

const backgroundPositionX = sample(['left', 'center', 'right', '25%', '75%'])

const backgroundPositionY = sample(['top', 'center', 'bottom', '25%', '75%'])

const blendModes = [
  'normal',
  'multiply',
  'screen',
  'overlay',
  'darken',
  'lighten',
  'color-dodge',
  'color-burn',
  'hard-light',
  'soft-light',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity'
]

const blendMode = sample(blendModes)

/*
 
  size: [width, height]

  outer borderRadius 
        -right
        -left
        -bottom
        -top

  inner borderRadius 

  outer border style
  outer border width
  outer border color

  inner border style
  inner border width
  inner border color

  wrapper bg
  wrapper padding
  wrapper boxShadow

  avatarImg
  
  

*/

const displays = [
  'block',
  'inline',
  'inline-block',
  'flex',
  'inline-flex',
  'grid',
  'table'
]
const display = sample(displays)

const borderStyles = [
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset'
]

const images = [
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'https://mrmrs.cc/photos/u/001.jpg',
  'https://mrmrs.cc/photos/u/002.jpg',
  'https://mrmrs.cc/photos/u/003.jpg',
  'https://mrmrs.cc/photos/u/004.jpg',
  'https://mrmrs.cc/photos/u/005.jpg',
  'https://mrmrs.cc/photos/u/006.jpg',
  'https://mrmrs.cc/photos/u/007.jpg',
  'https://mrmrs.cc/photos/u/008.jpg',
  'https://mrmrs.cc/photos/u/009.jpg',
  'https://mrmrs.cc/photos/u/010.jpg'
]

const generateDiv = color => {
  const shadowBool = randomInt(0, 1)
  var boxShadow = 'none'
  if (shadowBool > 0) {
    boxShadow = randomBoxShadow()
  }

  color = color || randomHex()

  const outerRadius = randomInt(0, 100)

  const radiusBool = randomInt(0, 10)

  const backgroundImage = sample(images)
  let outerRadiusMax = outerRadius
  let outerRadiusMin = outerRadius
  let innerRadiusMax = outerRadius
  let innerRadiusMin = outerRadius

  if (radiusBool > 5) {
    outerRadiusMax = randomInt(60, 100)
    outerRadiusMin = randomInt(10, 60)
    innerRadiusMax = randomInt(50, 100)
    innerRadiusMin = randomInt(10, 60)
  }

  const borderWidth = randomInt(0, 8)
  const outerBorderWidth = randomInt(0, 6)

  const size = randomInt(32, 80)
  const padding = randomInt(0, 8)

  const borderStyle = sample(borderStyles)
  const fontWeight = sample([
    100,
    200,
    300,
    400,
    400,
    400,
    500,
    500,
    500,
    600,
    600,
    600,
    700,
    700,
    700,
    800,
    800,
    800,
    900,
    900,
    900,
    900,
    900
  ])
  const fontSize = randomInt(12, 128)

  const boxSizing = sample(['border-box', 'content-box'])

  const div = {
    boxSizing: boxSizing,
    display: display,
    fontWeight: fontWeight,
    fontSize: fontSize,
    alignItems: 'center',
    parentBg: randomHex(),
    color: color, // set color for wrapper border
    borderColor: color, // set border color for image
    borderStyle: borderStyle,
    outerBorderStyle: borderStyle,
    bg: randomHex(), // set background of wrapper color
    //startColorHinting,
    //endColor: '#' + endColor,
    //endColorHinting,
    spread: 80,
    height: size,
    width: size,
    borderWidth: borderWidth,
    outerBorderWidth: borderWidth > 0 ? 0 : outerBorderWidth,
    padding: padding,
    paddingRight: padding,
    paddingLeft: padding,
    paddingTop: padding,
    paddingBottom: padding,
    topRightRadius: outerRadiusMin,
    topLeftRadius: outerRadiusMax,
    bottomRightRadius: outerRadiusMax,
    bottomLeftRadius: outerRadiusMin,
    mirrorTopRightRadius: outerRadiusMax,
    mirrorTopLeftRadius: outerRadiusMin,
    mirrorBottomRightRadius: outerRadiusMin,
    mirrorBottomLeftRadius: outerRadiusMax,
    boxShadow: boxShadow,
    borderTopWidth: '',
    borderTopStyle: '',
    borderTopColor: '#000',
    borderBottomWidth: '',
    borderBottomStyle: '',
    borderBottomColor: '#ff00ff',
    borderLeftWidth: '',
    borderLeftStyle: '',
    borderLeftColor: '#ffff33',
    borderRightWidth: '',
    borderRightStyle: '',
    borderRightColor: '#ee0044',
    borderImageSource: '',
    borderImageSlice: '',
    borderImageWidth: '',
    borderImageOutset: '',
    borderImageRepeat: '',
    boxShadowSpread: 2,
    boxShadowBlur: 8,
    boxShadowColor: 'rgba(0,0,0,.15)',
    boxShadowOffsetX: 2,
    boxShadowOffsetY: 2,
    boxShadowInset: sample([false, true]),
    outlineStyle: '',
    outlineWidth: '',
    outlineColor: '',
    text: 'Aa Bb Cc Dd',
    opacity: 100,
    fontWeight: '',
    textAlign: textAlign,
    textShadow: 'center',
    lineHeight: 100,
    letterSpacing: 0,
    textTransform: '',
    textShadowBlur: '',
    textShadowOffsetX: '',
    textShadowOffsetY: '',
    textShadowColor: '#000',
    fontStyle: '',
    whiteSpace: '',
    backgroundImage: backgroundImage,
    backgroundImageRepeat: backgroundImageRepeat,
    backgroundImageY: backgroundPositionY,
    backgroundImageX: backgroundPositionX,
    backgroundSize: backgroundSize,
    animationDelay: '',
    animationDirection: '',
    animationDuration: '',
    animationFillMode: '',
    animationIterationCount: '',
    animationName: '',
    animationPlayState: '',
    animationTimingFunction: '',
    maskClip: '',
    maskComposite: '',
    maskImage: '',
    maskMode: '',
    maskOrigin: '',
    maskRepeat: '',
    maskSize: '',
    maskType: '',
    mixBlendMode: blendMode,
    bgMixBlendMode: blendMode,
    blur: 0,
    fontStyle: 'normal',
    textDecorationColor: '#000',
    textDecorationLine: textDecorationLine,
    textDecorationStyle: textDecorationStyle
  }

  return {
    ...div
    //backgroundImage: toGradient(gradient)
  }
}

module.exports = generateDiv
